// i18next-extract-mark-ns-start affiliate
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Loader} from 'components/Loader';

//Clients
import corteIngles from 'images/corte-ingles.svg';
import iberdrola from 'images/iberdrola.svg';
import mediaMarkt from 'images/media_markt.svg';

//Images
import dollar from 'images/dollar.svg';
import check from 'images/check.svg';
import copy from 'images/copy.svg';
import heroImg from 'images/affiliates_hero.png';
import mobilePaymentSuccess from 'images/mobile_payment_success.png';
import tapfiliate from 'images/tapfiliate.png';

import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {containerSize, ScreenSizes} from '../../types/responsive';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {Content} from 'components/Content';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {Button} from 'components/Button';
import {FaqsSection} from 'components/FaqsSection';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(-58deg);
    left: 70%;
    top: 0;
    height: 770px;
    width: 1150px;
    z-index: -1;
    background: #aab4e9;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const IndexContent = styled.div`
  max-width: 33rem;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 32rem;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
    padding-top: 0.625rem;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
`;

const FormButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #78d9cf;
  height: 56px;
  padding: 0px 24px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: 250ms color;
  border: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  :hover {
    background-color: #71cec4;
  }

  @media (max-width: 680px) {
    border-radius: 6px;
    font-size: 14px;
    padding: 0px 16px;
    height: 48px;
  }
`;

const MainButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #78d9cf;
  border-radius: 4px;
  height: 56px;
  padding: 0px 24px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: 250ms color;
  border: 0px;

  :hover {
    background-color: #71cec4;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0px 16px;
    height: 48px;
  }
`;

const ButtonText = styled.p`
  font-weight: 600;
  color: white;
  margin-bottom: 0;
`;

const Caption = styled.p`
  font-size: 14px;
  color: #707070;
  font-weight: 600;
  padding-top: 56px;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const ClientGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ClientLogo = styled.img``;

const InfoSection = styled.section`
  padding-top: 30px;
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

const InfoWrapper = styled.div`
  display: grid;
  grid-column-gap: 5%;
  grid-template-columns: 50% 40%;
  align-items: start;
  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`;

const InfoLeft = styled.div`
  position: relative;
  @media (max-width: 768px) {
    order: 2;
  }
`;

const InfoRight = styled.div`
  position: relative;

  @media (max-width: 768px) {
    order: 1;
    padding-bottom: 40px;
  }
`;

const QrScan = styled.img`
  position: relative;
  z-index: 1;
`;

const SectionTitle = styled.h2`
  font-weight: 600;
  color: #000000;
  font-size: 26px;
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const BulletSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 24px;
  background-color: #78d9cf;
`;

const BulletText = styled.div`
  margin-bottom: 0px;
  font-weight: 500;
  color: black;
  padding-left: 16px;
`;

const BulletDescription = styled.p`
  margin-top: 8px;
  font-weight: 500;
  color: #707070;
  font-size: 16px;
  margin-bottom: 0px;
`;

const Icon = styled.img`
  margin-bottom: 0px;
`;

const HowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StepWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  height: 160px;
  @media (max-width: 768px) {
    height: 250px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
`;

const StepContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  border: 1px solid #dedede;
  height: 88px;
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 40px;
  background-color: #78d9cf;
  z-index: 1;
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const StepNumber = styled.p`
  font-weight: 600;
  color: white;
  font-size: 24px;
  margin-bottom: 0px;
`;

const StepDescription = styled.p`
  width: 40%;
  font-weight: 500;
  color: black;
  margin-bottom: 0px;
  font-size: 18px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 16px;
    width: 80%;
    padding-top: 24px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #ebebeb;
  padding: 12px 16px;
`;

const LinkText = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
  color: #5a5a5a;
  padding-right: 32px;
  @media (max-width: 768px) {
    padding-right: 8px;
    font-size: 14px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  padding-left: 16px;
`;

const BadgeTitle = styled.p`
  margin-bottom: 0px;
  font-weight: 500;
  color: black;
  @media (max-width: 768px) {
    line-height: 120%;
  }
`;

const BadgeSubtitle = styled.p`
  margin-bottom: 0px;
  color: #959595;
`;

const DottedLine = styled.div`
  position: absolute;
  left: 50%;
  width: 1px;
  border: 1px dashed #78d9cf;
  top: 0;
  bottom: 0;
  @media (max-width: 768px) {
    left: 20px;
  }
`;

const IndexImage = styled.div`
  margin: 0px -70px 0 0;
  @media (max-width: ${ScreenSizes.lg}) {
    margin: 0;
  }

  @media (max-width: ${ScreenSizes.md}) {
    margin: 3.5rem 0 0 0;
    text-align: center;
    img {
      width: 65%;
    }
  }
  @media (max-width: ${ScreenSizes.xs}) {
    display: none;
  }
`;

const CtaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
  }
`;

const Form = styled.form`
  margin: 0px;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 8px;
  @media (max-width: 680px) {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

const ErrorText = styled.p`
  color: red;
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const SuccessText = styled.p`
  color: #24c250;
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
`;

const HelpText = styled.p`
  opacity: 0.8;
  margin: 0px;
  font-size: 14px;
`;

const EmailInput = styled.input<{isError: any}>`
  background: #f8faff;
  border: ${({isError}) => (isError ? '2px solid red' : '1px solid #e2e2e2')};
  box-sizing: border-box;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 0px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #8992ae;
  height: 56px;

  :focus {
    outline: none !important;
    border: 2px solid #78d9cf;
  }
  @media (max-width: 680px) {
    border-radius: 6px;
  }
`;

type FormValues = {
  email: string;
  response?: string;
};

const initialValues: FormValues = {
  email: ''
};

const Index: React.FC = () => {
  const {t} = useI18next();
  const emailInput = useRef<HTMLInputElement>(null);

  const faqs = [
    {
      header: t('What is the MONEI Affiliate Program?'),
      text: t(
        'The MONEI Affiliate Program allows you to earn commissions by recommending MONEI to new merchants. When a merchant subscribes to MONEI using your unique affiliate link, you earn a percentage of their transactions. The more they use it, the higher your income! The program has two affiliate levels: Platinum and Gold.'
      ),
      content: (
        <Trans>
          The MONEI Affiliate Program allows you to earn commissions by recommending MONEI to new
          merchants. When a merchant subscribes to MONEI using your unique affiliate link, you earn
          a percentage of their transactions. The more they use it, the higher your income! The
          program has two affiliate levels: Platinum and Gold.
          <ul>
            <li>
              <strong>Platinum Affiliates:</strong> Generate the highest revenues for MONEI and, in
              turn, receive elevated commissions. This level is reserved for affiliates who achieve
              significant business impact.
            </li>
            <li>
              <strong>Gold Affiliates:</strong> Attract a diverse range of merchants and are in the
              process of building a more consistent revenue stream. They have the opportunity to
              move up to Platinum upon meeting commission goals.
            </li>
          </ul>
        </Trans>
      )
    },
    {
      header: t('When and how will I get paid?'),
      text: t(
        "Monthly payments are automated and directly deposited into the bank account you've set up in your affiliate dashboard. Additionally, you'll receive an automated invoice via email. Simply refer merchants to us and enjoy your earnings hassle-free."
      ),
      content: (
        <Trans>
          Monthly payments are automated and directly deposited into the bank account you've set up
          in your affiliate dashboard. Additionally, you'll receive an automated invoice via email.
          Simply refer merchants to us and enjoy your earnings hassle-free.
        </Trans>
      )
    },
    {
      header: t('How do I get my unique affiliate link?'),
      text: t(
        'Once you complete the registration, you will receive an email containing your unique referral link. When, AND ONLY WHEN, your first referral (merchant) becomes an active MONEI merchant, you will be granted access to your affiliate dashboard on Tapfiliate. In your dashboard, you can track all your progress, view earnings, and access various tools to optimize your affiliate performance.'
      ),
      content: (
        <Trans>
          Once you complete the registration, you will receive an email containing your unique
          referral link. When, AND ONLY WHEN, your first referral (merchant) becomes an active MONEI
          merchant, you will be granted access to your affiliate dashboard on <AnchorLink href="https://affiliates.monei.com">Tapfiliate</AnchorLink>. In your
          dashboard, you can track all your progress, view earnings, and access various tools to
          optimize your affiliate performance.
        </Trans>
      )
    },
    {
      header: t('How do I promote MONEI effectively?'),
      text: t(
        "We understand that the world of payments can be complex. If you're not familiar with our product, we can explain it to you, and we're happy to talk to your referrals about how MONEI can help them. We provide all the support you need to promote MONEI effectively."
      ),
      content: (
        <Trans>
          We understand that the world of payments can be complex. If you're not familiar with our
          product, we can explain it to you, and we're happy to talk to your referrals about how
          MONEI can help them. We provide all the support you need to promote MONEI effectively.
        </Trans>
      )
    },
    {
      header: t('How does MONEI help affiliates maximize their commissions?'),
      text: t(
        'We work hard to help grow your affiliate business and maximize your earnings. We want your merchants to feel supported, increase conversions, and help you increase your commissions.'
      ),
      content: (
        <Trans>
          We work hard to help grow your affiliate business and maximize your earnings. We want your
          merchants to feel supported, increase conversions, and help you increase your commissions.
          MONEI provides close collaboration, including:
          <ul>
            <li>Shared CRM strategies</li>
            <li>Strategic meetings</li>
            <li>
              Active communication channels to analyze how MONEI can boost your merchants’
              conversions, enabling you to earn higher commissions
            </li>
            <li>
              Help on content ideation to help you spread MONEI around (newsletter, blog post)
            </li>
          </ul>
        </Trans>
      )
    },
    {
      header: t('Need further assistance?'),
      text: t(
        'We’re dedicated to growing together. If you have any questions or need support, please reach out to us.'
      ),
      content: (
        <Trans>
          We’re dedicated to growing together. Contact us at{' '}
          <a href="mailto:affiliates@monei.com">affiliates@monei.com</a>, and a member of our sales
          team will connect with you promptly.
        </Trans>
      )
    }
  ];

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('should be a valid email')).required(t('email is required'))
  });

  const handleSubmit = async ({email}: FormValues, actions: any) => {
    try {
      const response = await fetch('https://api.monei.com/affiliates/create', {
        method: 'POST',
        body: JSON.stringify({email: email}),
        headers: {'Content-Type': 'application/json'}
      });

      if (response.ok) {
        actions.setStatus('success');
      }
    } catch (ex) {
      actions.setStatus('error');
    }

    actions.setSubmitting(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});

    if (emailInput.current !== null) {
      emailInput.current.focus();
    }
  };

  return (
    <>
      <SEO
        title={t('Earn up to 5,000 € per month as a MONEI affiliate')}
        description={t(
          'Be part of the future of payments with MONEI and get an extra income inviting other businesses.'
        )}
      />

      <IndexBackground>
        <Content>
          <Section centered sx={{marginTop: '1.875rem', flexDirection: {md: 'column'}}}>
            <IndexContent>
              <LargeSectionHeader underline tagName="h1">
                <Trans>Earn up to 5,000 € per month as a MONEI affiliate</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Get extra income when you invite other businesses to join the future of payments
                with MONEI
              </Trans>
              <SectionActions>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={validationSchema}>
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    status
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormGrid>
                        <EmailInput
                          ref={emailInput}
                          type="email"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          isError={errors.email && touched.email}
                          placeholder={t('Enter your email address...')}
                          disabled={status === 'success'}
                        />
                        <FormButton type="submit" disabled={isSubmitting || status === 'success'}>
                          {isSubmitting ? (
                            <Loader />
                          ) : (
                            <ButtonText>
                              <Trans>Join now</Trans>
                            </ButtonText>
                          )}
                        </FormButton>
                      </FormGrid>
                      {errors.email && touched.email ? <ErrorText>{errors.email}</ErrorText> : null}
                      <HelpText>
                        <Trans>
                          By signing up you agree to the MONEI Affiliate Program{' '}
                          <AnchorLink
                            target="_blank"
                            href="https://support.monei.com/hc/articles/360020603817-MONEI-Affiliate-Program-Terms-and-Conditions-">
                            Terms & Conditions
                          </AnchorLink>
                          .
                        </Trans>
                      </HelpText>
                      {status === 'error' ? (
                        <ErrorText>
                          <Trans>Unexpected error, try again later</Trans>
                        </ErrorText>
                      ) : null}
                      {status === 'success' ? (
                        <SuccessText>
                          <Trans>
                            Thanks for signing up. A message has been sent to your email inbox.
                          </Trans>
                        </SuccessText>
                      ) : null}
                    </Form>
                  )}
                </Formik>
                <Caption>
                  <Trans>MORE THAN 2,000 STORES ALREADY USE IT</Trans>
                </Caption>
                <ClientGrid>
                  <ClientLogo
                    src={mediaMarkt}
                    alt="MediaMarkt MONEI affiliate program"
                    title="MediaMarkt payment gateway MONEI affiliate program"
                    width={150}
                  />
                  <ClientLogo
                    src={iberdrola}
                    alt="Iberdrola MONEI affiliate program"
                    title="Iberdrola MONEI affiliate program"
                    width={150}
                  />
                  <ClientLogo
                    src={corteIngles}
                    alt="El Cort Ingles MONEI affiliate program"
                    title="El Cort Ingles MONEI affiliate program"
                    width={150}
                  />
                </ClientGrid>
              </SectionActions>
            </IndexContent>
            <IndexImage className="hide-on-tablet">
              <img src={heroImg} alt="MONEI Affiliate Program" />
            </IndexImage>
          </Section>
        </Content>
      </IndexBackground>

      <Content>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>Why affiliate with us?</Trans>
          </SectionHeader>
        </div>
        <InfoSection>
          <InfoWrapper>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>Maximize Your Lifetime Earnings</Trans>
                <BulletDescription>
                  <Trans>
                    Benefit from MONEI's success for life. As an affiliate, you'll continue to earn
                    from the merchants you bring in as long as they use MONEI. This lifetime earning
                    potential ensures your efforts pay off indefinitely.
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>Boost Your Income with Competitive Commissions</Trans>
                <BulletDescription>
                  <Trans>
                    Earn more based on the usage of MONEI by your referred merchants. The more they
                    use it, the more revenue you generate. The more you bring in, the more you earn!
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>Flexible Payout Options</Trans>
                <BulletDescription>
                  <Trans>
                    Choose to receive your commissions monthly or quarterly. Everything is
                    automated, so you can relax and enjoy your earnings. Just refer merchants to us
                    and start earning!
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>We Value Our Affiliates</Trans>
                <BulletDescription>
                  <Trans>
                    Our Platinum and Gold tiers ensure fair compensation based on the revenue and
                    customer value you bring. Enjoy varying commission rates tailored to your
                    referral style.
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>Track your Success Easily</Trans>
                <BulletDescription>
                  <Trans>
                    Monitor your performance regularly. Upon registration, you’ll receive a referral
                    link to share with merchants. Once your referral becomes an active MONEI user,
                    you'll get credentials to access your affiliate dashboard, where you can track
                    your progress and earnings.
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
            <BulletSection>
              <Circle>
                <Icon
                  src={check}
                  alt="MONEI affiliate program"
                  title="MONEI affiliate program"
                  width={12}
                />
              </Circle>
              <BulletText>
                <Trans>Grow with Us</Trans>
                <BulletDescription>
                  <Trans>
                    Our Sales team and Affiliates & Partners team are here to support you. We're
                    committed to growing together. Have questions or need assistance? Reach out to
                    us at{' '}
                    <AnchorLink href="mailto:affiliates@monei.com">affiliates@monei.com</AnchorLink>
                  </Trans>
                </BulletDescription>
              </BulletText>
            </BulletSection>
          </InfoWrapper>
          <div
            style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
            <Button variant="light" onClick={handleScrollToTop}>
              <Trans>Apply now</Trans>
            </Button>
          </div>
        </InfoSection>
      </Content>

      <Content>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>How to become a MONEI affiliate</Trans>
          </SectionHeader>
        </div>
        <Section style={{paddingTop: '30px'}}>
          <HowItWorks>
            <StepWrapper>
              <StepContent>
                <MainButton onClick={handleScrollToTop}>
                  <ButtonText>
                    <Trans>Join as an affiliate</Trans>
                  </ButtonText>
                </MainButton>
              </StepContent>
              <StepCircle>
                <StepNumber>1</StepNumber>
              </StepCircle>
              <StepDescription>
                <Trans>Apply to be an affiliate</Trans>
                <BulletDescription>
                  <Trans>
                    Enroll as an Affiliate and create your account in less than 10 seconds
                  </Trans>
                </BulletDescription>
              </StepDescription>
              <DottedLine />
            </StepWrapper>
            <StepWrapper>
              <StepContent>
                <LinkWrapper>
                  <LinkText>www.monei.com/?ref=miguel</LinkText>
                  <Icon
                    src={copy}
                    alt="MONEI affiliate program"
                    title="MONEI affiliate program"
                    width={24}
                  />
                </LinkWrapper>
              </StepContent>
              <StepCircle>
                <StepNumber>2</StepNumber>
              </StepCircle>
              <StepDescription>
                <Trans>Refer your MONEI Affiliate link to your network</Trans>
                <BulletDescription>
                  <Trans>Invite other merchants to join through your unique affiliate link. </Trans>
                </BulletDescription>
              </StepDescription>
              <DottedLine />
            </StepWrapper>
            <StepWrapper>
              <StepContent>
                <Row>
                  <Icon
                    src={dollar}
                    alt="MONEI affiliate program"
                    title="MONEI affiliate program"
                    width={32}
                  />
                  <Column>
                    <BadgeTitle>
                      <Trans>You have earned 10 € from merchant # 212</Trans>
                    </BadgeTitle>
                    <BadgeSubtitle>
                      <Trans>This month</Trans>
                    </BadgeSubtitle>
                  </Column>
                </Row>
              </StepContent>
              <StepCircle>
                <StepNumber>3</StepNumber>
              </StepCircle>
              <StepDescription>
                <Trans> Start Earning!</Trans>
                <BulletDescription>
                  <Trans>
                    Earn commissions based on the revenue generated by each merchant you refer. The
                    more they use MONEI through your link, the more you earn!
                  </Trans>
                </BulletDescription>
              </StepDescription>
            </StepWrapper>
          </HowItWorks>
        </Section>
      </Content>

      <Content>
        <GradientBox
          wrapperStyle={{overflow: 'visible', marginTop: '80px'}}
          contentStyle={{maxWidth: {all: '570px', md: '100%'}}}>
          <SectionHeader tagName="h2" underline style={{color: 'white'}}>
            <Trans>What are you waiting for? Join us now!</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              We look forward to partnering with you and growing together. Apply to our program in
              just 10 seconds and start earning!
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <Button variant="dark" onClick={handleScrollToTop}>
              <Trans>I want to join now</Trans>
            </Button>
          </SectionActions>
          <GradientCtaImage
            sx={{
              bottom: '00',
              right: '70px',
              width: '30%'
            }}
            src={tapfiliate}
          />
        </GradientBox>
      </Content>

      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>MONEI's Affiliate Program FAQ</Trans>} />
          <div
            style={{display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px'}}>
            <Button variant="light" onClick={handleScrollToTop}>
              <Trans>Apply now</Trans>
            </Button>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "affiliate"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
